import React, {
  CSSProperties,
  RefObject,
  useEffect,
  useMemo,
  useRef,
  useLayoutEffect,
} from 'react';
import { DeviceSettings } from 'vev';
import {
  getScrollHeight,
  getScrollTop,
  scrollEl,
  useFrame,
  useGlobalStore,
  useGlobalStateRef,
} from '../core';
import ViewManager from '../manager/view';
import ResizeObserver from 'resize-observer-polyfill';

interface ViewProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const sectionChildrenSelector = '.__sc';

function getMinMaxForDevice(
  deviceMode: string,
  deviceModes: DeviceSettings[],
): {
  max: number | null;
  min: number | null;
} {
  // Important to sort them, as min/max breakpoints are based on the previous mode in the list
  deviceModes = [...deviceModes].sort((a, b) => {
    return b.columnWidth[1] - a.columnWidth[1];
  });

  for (let i = 0; i < deviceModes.length; i++) {
    const mode = deviceModes[i];
    const prevMode = deviceModes[i - 1];

    if (mode.mode === deviceMode) {
      if (i === 0) {
        // First device, has no max width
        return { max: null, min: mode.columnWidth[1] };
      } else if (i === deviceModes.length - 1) {
        // Last device, has no min width
        return { max: (prevMode ? prevMode.columnWidth[1] : mode.columnWidth[0]) - 1, min: null };
      }
      // Should be a device in between two others
      return { max: prevMode ? prevMode.columnWidth[1] - 1 : null, min: mode.columnWidth[1] };
    }
  }

  return { max: null, min: null };
}

function getMediaQuery(max: number | null, min: number | null): string {
  if (max && min) {
    return `(max-width: ${max}px) and (min-width: ${min}px)`;
  } else if (max) {
    return `(max-width: ${max}px)`;
  } else if (min) {
    return `(min-width: ${min}px)`;
  }

  return '';
}

const hyphenateRegex = /[A-Z]|^ms/g;
const isCustomProperty = (property: string) => property.charCodeAt(1) === 45;

const toCSSAttr = (propertyName: keyof CSSProperties) => {
  return isCustomProperty(propertyName)
    ? propertyName
    : propertyName.replace(hyphenateRegex, '-$&').toLowerCase();
};

function toMediaQueryCssString(
  selector: string,
  css: CSSProperties,
  {
    max,
    min,
  }: {
    max: number | null;
    min: number | null;
  },
): string {
  let result = '';

  const mediaQuery = getMediaQuery(max, min);

  result += `@media all and ${mediaQuery} {\n`;
  result += toCssString(selector, css);
  result += '}\n';

  return result;
}

function toCssString(selector: string, css: CSSProperties): string {
  let result = '';

  result += `${selector} {\n`;

  for (const cssProp in css) {
    const cssValue = css[cssProp as keyof CSSProperties];
    result += `${toCSSAttr(cssProp as keyof CSSProperties)}: ${cssValue};\n`;
  }

  result += '}\n';

  return result;
}

function findDevice(devices: DeviceSettings[], width: number): DeviceSettings {
  return (
    devices.find((device, i, arr) => {
      const [, minWidth] = device.columnWidth;
      return width >= minWidth || i === arr.length - 1;
    }) || devices[0]
  );
}

function View({ children, style }: ViewProps, ref: React.RefObject<HTMLDivElement | undefined>) {
  const [project, editor, settings, device] = useGlobalStore((state) => [
    state.project,
    state.editor,
    state.settings,
    state.device,
  ]);
  const [stateRef, dispatch] = useGlobalStateRef();

  const scrollHeightRef = useRef<number>(-1);
  if (scrollHeightRef.current === -1) scrollHeightRef.current = scrollEl.scrollHeight;
  const scrollTopRef = useRef<number>(-1);
  if (scrollTopRef.current === -1) scrollTopRef.current = scrollEl.scrollTop;

  // const { width } = useSize(ref);

  useFrame(() => {
    const scrollHeight = getScrollHeight();
    if (scrollHeight !== scrollHeightRef.current) {
      scrollHeightRef.current = scrollHeight;
      dispatch('update-viewport');
    }
  }, []);

  useEffect(() => {
    const handleResize = () => dispatch('update-viewport');
    const handleScroll = () => {
      const top = getScrollTop();
      if (top !== scrollTopRef.current) {
        dispatch('scrollTop', top);
      }
    };

    handleResize();

    self.addEventListener('resize', handleResize, { passive: true });
    self.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      self.removeEventListener('resize', handleResize);
      self.removeEventListener('scroll', handleScroll);
    };
  }, [settings]);

  // const device = useMemo(() => {
  //   const device = findDevice(settings.devices, width);
  //   return device.mode;
  // }, [width, settings.devices]);

  // useEffect(() => dispatch('device', device), [device]);

  const useMediaQueries = !editor || editor.preRender;

  const css = useMemo(() => {
    // settings text side adjust to 0.99 to fix issues with samsung phones
    let res = `.p${project}{text-size-adjust:0.99}`;

    for (const device of settings.devices) {
      const [maxWidth, minWidth] = device.columnWidth;

      const { max, min } = getMinMaxForDevice(device.mode, settings.devices);
      const selector = `.p${project} ${sectionChildrenSelector}`;

      if (useMediaQueries) {
        res += `/* ${device.mode} */\n`;
        res += toMediaQueryCssString(
          selector,
          {
            width: '100%',
            maxWidth: `${maxWidth}px`,
            minWidth: `${minWidth}px`,
          },
          { max, min },
        );
      } else {
        res += toCssString(`.p${project}.${device.mode} ${sectionChildrenSelector}`, {
          width: '100%',
          maxWidth: `${maxWidth}px`,
          minWidth: `${minWidth}px`,
        });
      }
    }

    return res;
  }, [settings.devices, useMediaQueries]);

  useLayoutEffect(() => {
    if (!project || editor?.disabled || editor?.preRender) return;

    const styleEL = document.createElement('style');
    document.body.appendChild(styleEL);

    let prevZoom: number;
    const observer = new ResizeObserver((entries) => {
      const { width } = entries[0] && entries[0].contentRect;

      const { settings, device: deviceMode, editor } = stateRef.current;

      if (!editor || (!editor.disabled && !editor.preRender)) {
        const device = findDevice(settings.devices, width);
        if (device.mode !== deviceMode) dispatch('device', device.mode);
        const [maxColumnWidth, minColumnWidth] = device.columnWidth;
        const fullWidth = device.scaling;
        const size = fullWidth ? maxColumnWidth : minColumnWidth;

        let zoom = Math.round((width / size) * 100) / 100;
        if (!fullWidth && zoom > 1) zoom = 1;

        if (prevZoom === zoom) return;
        /** Need to update deprecated ViewManager */
        ViewManager.zoom = prevZoom = zoom;
        dispatch('zoom', zoom);
        // Text size adjust can not be 100% because then samsung internet falls back on browsers default text size adjust which is not necessarily 100% fucks up everything
        const textSizeAdjust = `${Math.round((zoom === 1 ? 0.99 : zoom) * 100)}%`;
        styleEL.innerText =
          `.p${project} .__p,.p${project} .__f{` +
          `-webkit-text-size-adjust: ${textSizeAdjust};` +
          `-ms-text-size-adjust: ${textSizeAdjust};` +
          `-moz-text-size-adjust: ${textSizeAdjust};` +
          `text-size-adjust: ${textSizeAdjust};` +
          (zoom !== 1 ? `zoom: ${zoom};` : '') +
          '}';
      }
    });

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
      styleEL.remove();
    };
  }, [project, editor?.disabled]);

  useLayoutEffect(() => {
    const classes = ['p' + project, device];

    if (ViewManager.isIOS) classes.push('ios');
    if (ViewManager.isAndroid) classes.push('android');
    if (ViewManager.isIE) classes.push('ie');
    if (ViewManager.isChrome) classes.push('chrome');
    if (ViewManager.isFirefox) classes.push('firefox');
    if (ViewManager.isOpera) classes.push('opera');
    // Edge tries to be safari
    if (ViewManager.isEdge) classes.push('edge');
    else if (ViewManager.isSafari) classes.push('safari');

    ref.current?.setAttribute('class', classes.join(' '));
  }, [project, device]);

  return (
    <div style={style} ref={ref}>
      <style suppressHydrationWarning>{css}</style>
      {children}
    </div>
  );
}

export default React.forwardRef(View);
