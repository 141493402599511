import { PortalProps, AnchorOrigin } from 'vev';
import React, { useState, useEffect, useLayoutEffect, useCallback, useRef } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import ReactDOM from 'react-dom';
import { removeEl } from '../../utils';
import { Mouse } from '../../manager';
import { useModel, useScrollTop, useGlobalStateRef } from '../../core/hooks';
import { createAnimation } from '../../utils/animation';

const ANCHOR_POS = { top: 0, center: 0.5, bottom: 1, left: 0, right: 1 };

function convertAnchor(pos: AnchorOrigin): [number, number] {
  const [y, x] = pos.split('-');
  return [
    ANCHOR_POS[x as keyof typeof ANCHOR_POS] || 0,
    ANCHOR_POS[y as keyof typeof ANCHOR_POS] || 0,
  ];
}

export default function Portal({
  className,
  back,
  hide,
  style,
  animation,
  children,
  anchor,
  anchorOrigin,
  portalOrigin,
  dock,
  offsetX,
  offsetY,
  noInherit,
  closeTrigger,
  onRequestClose,
}: PortalProps): React.ReactPortal {
  const anchorEl: Element | undefined =
    anchor && 'current' in anchor ? anchor.current : (anchor as Element | undefined);
  const model = useModel();
  const [state] = useGlobalStateRef();
  const showState = useRef<'enter' | 'leave' | false>(false);

  const [observer] = useState(() => new ResizeObserver(() => setTimeout(pos, 0)));
  const scrollTop = useScrollTop();
  const [el] = useState(() => {
    const el = document.createElement('div');
    let cl = 'vev-portal';
    if (back) cl += ' back';

    el.className = cl;
    if (!noInherit && model) {
      el.id = model.key + 'c';
      el.className += ' ' + model.cl;
    }

    return el;
  });

  useLayoutEffect(() => pos(), [scrollTop]);

  useEffect(() => {
    observer.observe(el);
    return () => {
      el.removeEventListener('mouseleave', handleCloseTrigger);
      Mouse.off('mousedown', handleCloseTrigger);
      observer.disconnect();
      remove();
    };
  }, []);

  useEffect(() => {
    if (anchorEl) observer.observe(anchorEl);
  }, [anchorEl]);

  useEffect(() => {
    if (animation) {
      if (!hide && !showState.current) {
        add();
        createAnimation(el, animation);
      } else if (hide && showState.current === 'enter') {
        showState.current = 'leave';
        createAnimation(el, animation, true, remove);
      }
    } else hide ? remove() : add();
  });

  const remove = () => {
    showState.current = false;
    removeEl(el);
  };
  const add = () => {
    showState.current = 'enter';
    (state.current.root || document.body).appendChild(el);
    handleOpen();
    pos();
  };

  const handleOpen = () => {
    if (onRequestClose) {
      switch (closeTrigger) {
        case 'mouseleave':
          el.removeEventListener('mouseleave', handleCloseTrigger);
          el.addEventListener('mouseleave', handleCloseTrigger);
          break;
        case 'click':
          Mouse.on('mousedown', handleCloseTrigger);
          break;
      }
    }
  };

  const handleCloseTrigger = useCallback((e: MouseEvent) => {
    if (onRequestClose) {
      if (
        e.type !== 'mousedown' ||
        (!Mouse.isInside(e, el) && (!anchorEl || !Mouse.isInside(e, anchorEl)))
      ) {
        onRequestClose(e);
      }
    }
  }, []);

  const pos = () => {
    if (hide) return;
    let top: string | number = 'auto',
      left: string | number = 'auto',
      right: string | number = 'auto',
      bottom: string | number = 'auto',
      width = 'auto',
      height = 'auto';
    if (dock) {
      if (/(top|left|bottom)/i.test(dock)) left = 0;
      if (/(top|bottom|right)/i.test(dock)) right = 0;
      if (/(top|left|right)/i.test(dock)) top = 0;
      if (/(bottom|left|right)/i.test(dock)) bottom = 0;
    } else {
      left = top = 0;
      if (anchorEl) {
        const [anchorX, anchorY] = convertAnchor(anchorOrigin || 'bottom-left');
        let rect = anchorEl.getBoundingClientRect();

        top = rect.top + (offsetY || 0) + rect.height * anchorY;
        left = rect.left + (offsetX || 0) + rect.width * anchorX;

        rect = el.getBoundingClientRect();
        const [targetX, targetY] = convertAnchor(portalOrigin || 'top-left');
        left -= rect.width * targetX;
        top -= rect.height * targetY;
        top = Math.max(0, Math.min(top, window.innerHeight - rect.height));
        left = Math.max(0, Math.min(left, window.innerWidth - rect.width));
      } else {
        top += offsetX || 0;
        left += offsetY || 0;
      }
      top = top + 'px';
      left = left + 'px';
    }
    Object.assign(el.style, { top, bottom, left, right, width, height });
  };

  return ReactDOM.createPortal(
    <div style={style} className={className}>
      {children}
    </div>,
    el,
  );
}
