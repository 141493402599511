import { IRegistered, IModule } from './types';
import { topLevelLoad, loadTag, resolveIfNotPlainOrUrl, loadCss } from './utils';
import { global, removeEl } from '../utils/dom';
import { isString } from '../utils/type';

const register: { [key: string]: IModule } = ((window as any).register = {
  vev: { id: 'vev', n: (global as any).vev, i: [] },
});

function emptyFn() {
  return {};
}

const registerRegistry: { [id: string]: IRegistered } = ((global as any).registerRegistry = {});

const watchers: { [id: string]: ((resolved: any) => void)[] } = {};

const resolve: { [key: string]: string } = {};
let lastRegister: IRegistered | undefined;

let baseUrl = typeof location !== 'undefined' ? location.href.split('#')[0].split('?')[0] : '';
const lastSepIndex = baseUrl.lastIndexOf('/');
if (lastSepIndex !== -1) baseUrl = baseUrl.slice(0, lastSepIndex + 1);

class System {
  r = register;
  rr = registerRegistry;
  re = resolve;
  ls() {
    console.log('r', this.r);
    console.log('rr', this.rr);
  }
  get(id: string): IModule | void {
    const load = register[id] || register[this.resolve(id)];
    if (load && load.e === null && !load.E) {
      if (load.eE) return;
      // Maybe load.n ??
      return load;
    }
  }

  delete(id: string): void {
    delete registerRegistry[id];
    delete register[id];
    const load = this.get(id);
    if (load && load.t) removeEl(load.t);
  }

  register(deps: string[], func: Function): void;
  register(key: string, deps: string[], func: Function): void;
  register(key: string | string[], deps: string[] | Function, func?: Function): void {
    if (isString(key)) {
      lastRegister = registerRegistry[key] = [deps as string[], func as Function];
      this.emitWatchers(key);
    } else {
      lastRegister = [key as string[], deps as Function];
    }
  }

  resolve(id: string, parentUrl?: string): string {
    if (register[id] || registerRegistry[id]) return id;
    return resolveIfNotPlainOrUrl(id, parentUrl || baseUrl);
  }

  async instantiate(url: string): Promise<IRegistered | undefined> {
    if (registerRegistry[url]) return registerRegistry[url];
    await this.fetch(url);
    const _last = lastRegister;
    lastRegister = undefined;
    return _last || [[], emptyFn];
  }

  private async emitWatchers(key: string) {
    const r = await this.import(key, true);
    const list = watchers[key] || [];
    for (const cb of list) cb(r);
  }

  /**
   * key: widgetId
   * cb:
   * pkg:
   */
  watch(pkgKey: string, cb: (resolved: any) => any) {
    const list = watchers[pkgKey] || (watchers[pkgKey] = []);
    if (list.indexOf(cb) === -1) list.push(cb);

    const mod = this.syncImport(pkgKey);
    if (mod) cb(mod);
    return () => list.splice(list.indexOf(cb), 1);
  }

  unwatch(key: string, cb: (resolved: any) => any) {
    const list = watchers[key];
    let index: number;
    if (list && (index = list.indexOf(cb)) !== -1) list.splice(index, 1);
  }

  /** Import sync only works if module is loaded/imported before  */
  syncImport(path: string): { [key: string]: any } {
    if (register[path]) {
      const load = getOrLoad(this.resolve(path));
      return load.n;
    }
    return {};
  }

  async import(path: string, isRegisteredPkg?: boolean): Promise<{ [key: string]: any }> {
    if (path === 'react') return (self as any).React;
    if (path === 'react-dom') return (self as any).ReactDOM;

    if (isRegisteredPkg && !registerRegistry[path]) return Promise.resolve({});
    const load = getOrLoad(this.resolve(path));
    await (load.C || topLevelLoad(load));

    return load.n;
  }

  fetch(url: string): Promise<void> {
    return loadTag(url);
  }

  add(map: { [key: string]: any }) {
    for (const key in map) {
      register[key] = {
        id: key,
        n: map[key],
        i: [],
        C: Promise.resolve(),
      };
    }
  }
}

const system = new System();

function getOrLoad(id: string): IModule {
  if (register[id]) return register[id];
  if (/\.css$/i.test(id)) {
    return (register[id] = {
      id,
      C: Promise.resolve(),
      n: {},
      i: [],
      t: loadCss(id),
    });
  }

  const load: IModule = (register[id] = {
    id,
    // importerSetters, the setters functions registered to this dependency
    // we retain this to add more later
    i: [],
    // module namespace object
    n: {},

    // instantiate
    // I: doLoad(load),
    // link
    // L: false, // linkPromise,
    // whether it has hoisted exports
    h: false,

    // On instantiate completion we have populated:
    // dependency load records
    d: [],
    // execution function
    // set to NULL immediately after execution (or on any failure) to indicate execution has happened
    // in such a case, pC should be used, and pLo, pLi will be emptied
    e: () => {},

    // On execution we have populated:
    // the execution error if any
    eE: undefined,
    // in the case of TLA, the execution promise
    E: undefined,

    // On execution, pLi, pLo, e cleared

    // Promise for top-level completion
    // C: undefined
  });

  load.I = doLoad(load);
  load.L = load.I.then(async ([deps, setters]) => {
    load.d = await loadDeps(id, deps, setters);
  });
  return load;

  // return [registration[0], declared.setters || []];
}

async function doLoad(load: IModule): Promise<[string[], any[]]> {
  const { n: ns, i: importerSetters } = load;

  const registration = await system.instantiate(load.id);
  if (!registration) throw new Error('Module ' + load.id + ' did not instantiate');

  function _export(name, value) {
    // note if we have hoisted exports (including reexports)
    load.h = true;
    let changed = false;
    if (name === 'default' && typeof value === 'object') name = value;

    if (typeof name !== 'object') {
      if (!(name in ns) || ns[name] !== value) {
        ns[name] = value;
        changed = true;
      }
    } else {
      for (const p in name) {
        const value = name[p];
        if (!(p in ns) || ns[p] !== value) {
          ns[p] = value;
          changed = true;
        }
      }
    }
    if (changed) {
      for (let i = 0; i < importerSetters.length; i++) importerSetters[i](ns);
    }
    return value;
  }

  const declared = registration[1](_export);

  load.e = declared.execute || function () {};

  return [registration[0], declared.setters || []];
}

async function loadDeps(id: string, deps: string[], setters): Promise<IModule[]> {
  return Promise.all(
    deps.map(async (dep, i) => {
      const setter = setters[i];
      const depLoad = getOrLoad(system.resolve(dep, id));
      await depLoad.I;
      if (setter) {
        depLoad.i.push(setter);
        // only run early setters when there are hoisted exports of that module
        // the timing works here as pending hoisted export calls will trigger through importerSetters
        if (depLoad.h || !depLoad.I) setter(depLoad.n);
      }
      return depLoad;
    }),
  );
}

export default system;
