import './globals';
import './viewer.scss';
// import { IAppState } from 'vev';
// import React from 'react';
// import ReactDOM from 'react-dom';
// import { App, StateProvider, View } from './core';
// import polyfills from './core/polyfills';
// import system from './system';
// import { pageKeyByPath } from './utils/route';

import * as vev from './core';

if (!(window as any).vev) (window as any).vev = vev;
else console.warn('Multiple vev scripts loaded');
// function importAll(state: IAppState): Promise<any> {
//   let imports = {};
//   for (const { type } of state.models) imports[type] = state.pkg[type] || type;
//   return Promise.all(Object.keys(imports).map((key) => system.import(key, true)));
// }

// export async function render(
//   state: IAppState,
//   root: HTMLElement = document.getElementById(state.project),
//   doHydrate: boolean = true
// ) {
//   if (!root) return console.error('Failed to render vev, missing root node');

//   View.updateSize();
//   await Promise.all([importAll(state), polyfills()]);

//   // state.embed = root.hasAttribute('embed');
//   if (!state.route) {
//     // const pageKey = state.embed
//     //   ? root.getAttribute('embed') || state.pages.find((p) => p.index).key
//     //   : pageKeyByPath(location.pathname, state.pages, state.dir);
//     state.route = { pageKey: pageKeyByPath(location.pathname, state.pages, state.dir) };
//   }

//   console.log(state);
//   const jsx = (
//     <StateProvider state={state}>
//       <App />
//     </StateProvider>
//   );

//   if (doHydrate) ReactDOM.hydrate(jsx, root);
//   else ReactDOM.render(jsx, root);
// }

declare const vevr: () => void;
declare const vevc: () => void;
// Check if pending vev widget register exits (window.vevr). Added by widget script if loaded before window.vev exists
if (typeof vevr !== 'undefined') setTimeout(vevr, 0);
// Check if pending vev content render exits (window.vevr). Added by content script if loaded before window.vev exists
if (typeof vevc !== 'undefined') setTimeout(vevc, 0);
