import React from 'react';
import { ImageProps } from 'vev';
import { isString } from '../../utils';
import { useImage } from '../../core/hooks';

type ImageSize = [number, number];

function clean(str: string): string {
  return (str || '').replace(/\n/g, ' ').replace(/[^\w,\.\!\-_$% :\/]/gi, '');
}

function getSize([deviceSize, resSize]: ImageSize) {
  return `(max-width: ${deviceSize}px) ${resSize}px`;
}

function sortByDevice(s1: ImageSize, s2: ImageSize) {
  return s1[0] - s2[0];
}

export default function Image({ src, sizes: modeSizes, style, className }: ImageProps) {
  const img = useImage(src ? (src as any).key || src : '');

  let srcSet: any, sizes: any, alt: any;
  if (img) {
    // console.log(img)
    if (modeSizes) {
      modeSizes.sort(sortByDevice);
      sizes = '';
      const len = modeSizes.length - 1;
      for (let i = 0; i < len; i++) sizes += getSize(modeSizes[i]) + ' ';
      sizes += modeSizes[len][1] + 'px';
    }

    srcSet = img.srcset.map((imgSize) => `${imgSize[0]} ${imgSize[1]}w`).join(',');
    src = img.src || (img as any).url;

    if (!src && img.srcset[img.srcset.length - 1]) src = img.srcset[img.srcset.length - 1][0];
    alt = img.meta && img.meta.description;
  }

  if (isString(src) && /^\/\//.test(src)) src = 'https:' + src;

  return (
    <img
      style={style}
      className={className}
      src={isString(src) ? src : ''}
      srcSet={srcSet}
      sizes={sizes}
      alt={clean(alt)}
    />
  );
}
