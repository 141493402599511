import anime from 'animejs';
import { ITweenValues, ITween, AnimeTarget } from 'vev';

const DEFAULT_TIME = 1;
export const EASING_FUNCS = [
  'linear',
  'easeInQuad',
  'easeOutQuad',
  'easeInOutQuad',
  'easeInElastic()',
  'easeOutElastic()',
  'easeInOutElastic()',
  'spring()',
  'spring()',
  'spring()',
  'easeInBack',
  'easeOutBack',
  'easeInOutBack',
];

export default class Timeline {
  private scale: { [attr in keyof ITweenValues]: number } = {};
  private replace: { [attr: string]: string } = {
    x: 'translateX',
    y: 'translateY',
  };
  private format: { [attr: string]: (value: any) => any } = {};

  instance: anime.AnimeTimelineInstance;

  constructor(onComplete: () => any, onStart?: () => any) {
    this.instance = anime.timeline({
      autoplay: false,
      complete: onComplete,
      begin: onStart,
    });
  }
  /**
   * `play` start playing the animation
   */
  play() {
    this.instance.play();
  }

  /**
   * `reverse` start playing the animation in reverse
   */
  reverse() {
    this.instance.reverse();
    this.instance.completed = false;
    this.instance.play();
  }

  /**
   * `remove` removes all animations added to the give target
   */
  remove(target: HTMLElement) {
    anime.remove(target);
  }

  /**
   * `reset` resets the animation to the starting point
   */
  reset() {
    this.instance.pause();
    this.instance = anime.timeline({
      autoplay: false,
      complete: this.instance.complete,
      begin: this.instance.begin,
    });
    this.instance.pause();
  }

  /**
   * `scaling` set custom scaling on attributes, scaling multiplied with tween value
   */
  scaling(scale: { [attr in keyof ITweenValues]: number }): void {
    this.scale = scale;
  }

  addReplace<T extends keyof ITween>(attr: T, replaceAttr: string) {
    this.replace[attr] = replaceAttr;
  }
  /**
   * `addFormat` set custom formatter function on given attributes
   */
  addFormat<T extends keyof ITween>(attr: T, formatFunc: (value: any) => any) {
    this.format[attr] = formatFunc;
  }

  /**
   * `toValues` adds tween to the timeline instance which animates to the given values
   */
  toValues(targets: AnimeTarget, tweens: ITween[] | ITween, toValues: ITweenValues) {
    if (!tweens || !targets) return;
    if (!Array.isArray(tweens)) tweens = [tweens];

    // Cloning to values to not screw them up when writing to the object
    toValues = { ...toValues };
    // Calculate the total time for all the tweens, because we need to build the tween backwards becuase the end values is known
    let currentTime = tweens.reduce((value, tween) => value + (tween.time || 1), 0);
    let i = tweens.length;

    // Loop over the tweens backwards where the first tweens is set to the to values
    while (i--) {
      const tween = tweens[i];
      const time = tween.time || DEFAULT_TIME;
      currentTime -= time;
      for (const attr in tween) {
        if (attr !== 'time') {
          const [value, easing, startOffset, endOffset] = tween[attr];
          // if (scale[attr]) value *= scale[attr];

          this.addTween(
            targets,
            attr as keyof ITweenValues,
            value,
            toValues[attr],
            easing,
            startOffset * time + currentTime,
            endOffset * time + currentTime,
          );

          toValues[attr] = value;
        }
      }
    }
  }

  /**
   * `fromValues` adds tween to the timeline instance which animates from the given values
   */
  fromValues(target: AnimeTarget, tweens: ITween[] | ITween, fromValues: ITweenValues) {
    if (!tweens || !target) return;
    if (!(tweens instanceof Array)) tweens = [tweens];

    fromValues = { ...fromValues };

    let currentTime = 0;
    for (const tween of tweens) {
      const time = tween.time || DEFAULT_TIME;
      for (const attr in tween) {
        if (attr !== 'time') {
          const [value, easing, startOffset, endOffset] = tween[attr];

          this.addTween(
            target,
            attr as keyof ITweenValues,
            fromValues[attr],
            value,
            easing,
            startOffset * time + currentTime,
            endOffset * time + currentTime,
          );

          fromValues[attr] = value;
        }
      }

      currentTime += time;
    }
  }

  /**
   * `addTween` adds tween to the timeline instance
   */
  private addTween<K extends keyof ITweenValues>(
    targets: AnimeTarget,
    attr: K,
    fromValue: number,
    toValue: number,
    easing: number | string,
    startTime: number,
    endTime: number,
  ) {
    if (this.format[attr]) {
      fromValue = this.format[attr](fromValue);
      toValue = this.format[attr](toValue);
    }

    if (attr in this.scale) {
      fromValue *= this.scale[attr] ?? 0;
      toValue *= this.scale[attr] ?? 0;
    }

    // @ts-ignore
    this.instance.add(
      {
        targets,
        delay: startTime * 1000,
        duration: (endTime - startTime) * 1000,
        easing: typeof easing === 'string' ? easing : EASING_FUNCS[easing],
        [this.replace[attr] || attr]: [fromValue, toValue],
      },
      0,
    );
  }
}
