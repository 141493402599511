import { IObserverOptions } from 'vev';
import System from '../system';
import { isNumber } from './type';

type IObserved = {
  el: Element;
  cb: (entry: IntersectionObserverEntry) => any;
  observer: IntersectionObserver;
};

const formatOffset = (value?: number | string): string => {
  if (isNumber(value)) return value * -1 + 'px';
  if (value) return value.charAt(0) === '-' ? value.substr(1) : '-' + value;
  return '0px';
};

class InterSectionObserverManager {
  observers: { [key: string]: IntersectionObserver } = {};
  observed: IObserved[] = [];

  add(el: Element, cb: (entry: IntersectionObserverEntry) => any, options?: IObserverOptions) {
    // let threshold: any, rootMargin: any;

    let observerOpts;
    // Init threshold
    if (options) {
      if (typeof options === 'number') options = { steps: options };
      const { offsetTop, offsetBottom, steps, threshold } = options;

      observerOpts = {};
      if (offsetTop || offsetBottom) {
        observerOpts.rootMargin = `${formatOffset(offsetTop)} 0px ${formatOffset(
          offsetBottom,
        )} 0px`;
        observerOpts.root = null;
      }

      if (steps) {
        observerOpts.threshold = [];
        for (let i = 0; i <= steps; i++) observerOpts.threshold.push(i / steps);
      } else if (threshold) observerOpts.threshold = threshold;
    }

    // Generate uniq observer key based on the threshold (trying to reuse observers)
    const key = observerOpts ? JSON.stringify(observerOpts) : 'default'; // (threshold ? threshold.toString() : 'default') + rootMargin;
    // Remove element from any existing observers
    // this.remove(el);
    // Get existing observer or create new observer
    const observer =
      this.observers[key] ||
      (this.observers[key] = new IntersectionObserver(this.cb, observerOpts));
    observer.observe(el);
    this.observed.push({ el, observer, cb });
  }

  remove(element: Element) {
    const index = this.observed.findIndex((m) => m.el === element);
    if (index !== -1) {
      this.observed[index].observer.unobserve(element);
      this.observed.splice(index, 1);
    }
  }

  private cb = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
    for (const entry of entries) {
      const o = this.find(entry.target);
      if (o) o.cb(entry);
    }
  };

  private find(el: Element): IObserved | void {
    for (const o of this.observed) {
      if (o.el === el) return o;
    }
  }
}

export default new InterSectionObserverManager();
